/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "@fontsource/open-sans/500.css"
import "@fontsource/open-sans/700.css"
import "antd/dist/antd.css"
// Import slick carousel CSS
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./src/styles/global.css"

// Import logos for preloading

// Add preload styles to prevent layout shifts
export const onClientEntry = () => {
  // Create style element to reserve space
  const style = document.createElement("style")
  style.innerHTML = `
    /* Reserve space for components to prevent layout shifts */
    .header-full {
      min-height: 180px;
      position: relative;
    }
    
    .header-mobile {
      height: 64px;
      min-height: 64px;
    }
    
    /* Navbar dimensions - matching original with exact needed dimensions */
    .header-full nav {
      display: flex;
      justify-content: center;
      border-top-width: 0.5px;
      border-bottom-width: 0.5px;
    }
    
    .header-full nav > div {
      display: flex;
      align-items: center;
    }
    
    /* Search container dimensions */
    [class*="items-center pr-4 absolute right-0"] {
      position: absolute;
      right: 0;
    }
    
    /* Mobile navbar exact dimensions */
    .header-mobile .flex.xxx {
      height: 48px;
    }
    
    .sp-slider {
      min-height: 400px;
      margin: 0 auto;
      max-width: 1180px;
    }
    
    /* Fix for layout shifts during image loading */
    img.logo {
      opacity: 0;
      transition: opacity 0.3s ease-in;
    }
    
    body.loaded img.logo {
      opacity: 1;
    }
  `
  document.head.appendChild(style)

  // Create a "loaded" class after full load to reveal content
  window.addEventListener("load", () => {
    document.body.classList.add("loaded")

    // Remove pointer-events prevention after load
    const style = document.createElement("style")
    style.innerHTML = `[class*="items-center pr-4 absolute right-0"] { pointer-events: auto; }`
    document.head.appendChild(style)
  })

  // We don't need to preload images with link tags since we're importing them above
  // and Gatsby will handle bundling them correctly
}
